// $gris-oscuro: #2c2728;
// $rosa: #cb347d;
// $morado: #553c83;
// $verde: #4BB164;
// $crema: #ff585d;

@import './variables';

@font-face {
	font-family: 'Poppins Regular';
	src: url('../../resources/fonts/Poppins-Regular.ttf');
}

@font-face {
	font-family: 'Poppins Bold';
	src: url('../../resources/fonts/Poppins-Bold.ttf');
}

body {
	margin: 0;
	font-family: 'Poppins Regular';
	// font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	// color: #fff;
	font-size: 18px;
}

#root::after {
	content: '';
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;
	position: fixed;
	z-index: -1;
	background-repeat: no-repeat;
	background-position-y: 100%;
	background-position-x: center;
	background-size: cover;
	background-position: top;
	background-image: url(../../resources/images/bg.png);
}

.full {
	width: 100%;
}

.center {
	text-align: center;
}

.left {
	text-align: left;
}

.right {
	text-align: right;
}

.cursor {
	cursor: pointer;
}

.bold {
	font-weight: bold;
}

.padre {
	display: table;

	& .hijos {
		display: table-cell;
		vertical-align: middle;
	}
}

.alignCenter {
	justify-content: center;
	align-items: center;
	display: flex;
}

.mt30 {
	margin-top: 30px;
}

.mb30 {
	margin-bottom: 30px;
}

.titulo {
	color: $verde;
	font-family: 'Poppins Bold';
}

.MuiInputBase-input {
	color: $gris-oscuro !important;
	// font-family: "Montserrat Regular";
	font-size: 22px;
}

.MuiFormLabel-root {
	color: $gris-oscuro !important;
	// color: $morado !important;
	// font-family: "Montserrat Regular" !important;
}

.MuiInputLabel-outlined.MuiInputLabel-shrink {
	transform: translate(0px, -18px) scale(1) !important;
	// font-family: "Montserrat Regular" !important;
	color: $gris-oscuro !important;
}

.MuiInputBase-root {
	// margin-bottom: 15px;
	// border-radius: 20px;
	background-color: #ebebeb;
}

.MuiFilledInput-underline {
	&::before,
	&::after {
		border: none !important;
	}
}

.MuiFormLabel-root.Mui-focused, .Mui-focused {
	color: $gris-oscuro !important;
	font-weight: normal;
	font-size: 14px;
	background-color: #ebebeb !important;
}

label.MuiFormControlLabel-root {
	color: $gris-oscuro !important;
	font-family: 'Montserrat Regular';
}

.fondoMenu {
	background: $crema;
	max-width: 540px;
	height: 100vh;
	width: 100vw;
	overflow: auto;
	padding: 0 0 0 0;
	color: #fff;
	z-index: 2;

	& .opciones {
		margin-top: 20px;
		padding-left: 20px;

		& .lista {
			display: grid;
		}
		& button {
			color: #fff;
			padding-left: 10px;
			width: 200px;
			line-height: 1.1;
			font-size: 15;
			font-weight: bold;
			text-transform: none;
			text-decoration: none !important;
		}
	}
}

.alice-carousel__dots-item:not(.__custom):hover,
.alice-carousel__dots-item:not(.__custom).__active {
	background: $vino !important;
}

@media only screen and (max-width: 600px) {
	.header {
		& #menuHeader {
			display: none;
		}
		& #menu-mobile {
			display: block;
		}
		& .open-mobile {
			display: block;
		}
	}
}

@media only screen and (min-width: 600px) {
	.header {
		& #menuHeader {
			display: none;
		}
		& #menu-mobile {
			display: block;
		}
		& .open-mobile {
			display: block;
		}
	}
}

@media only screen and (min-width: 768px) {
}

@media only screen and (min-width: 992px) {
	.header {
		& #menuHeader {
			display: flex;
		}
		& #menu-mobile {
			display: none;
		}
		& .open-mobile {
			display: none;
		}
	}
}

@media only screen and (min-width: 1200px) {
	.header {
		& #menuHeader {
			display: flex;
		}
		& #menu-mobile {
			display: none;
		}
		& .open-mobile {
			display: none;
		}
	}
}
