@import '../../resources/sass/variables';

.loader {
	position: relative;
	height: 100vh;
	display: flex;
	justify-content: center;
	align-items: center;
	text-align: left !important;
	flex-wrap: wrap;

	& img {
		width: 16%;
	}

	& .outer,
	.middle,
	.inner {
		border: 8px solid transparent;
		// border-top-color: $color-active;
		// border-right-color: $color-active;
		border-radius: 50%;
		position: absolute;
		top: 50%;
		left: 50%;
	}

	& .outer {
		width: 24em;
		height: 24em;
		margin-left: -12.7em;
		margin-top: -12.7em;
		animation: spin 2s linear infinite;
		border-top-color: blue;
		border-left-color: blue;
		// border-right-color: #005aa6;
		// border-bottom-color: #005aa6;
	}

	& .middle {
		width: 20em;
		height: 20em;
		margin-left: -10.75em;
		margin-top: -10.75em;
		animation: spin 1.75s linear reverse infinite;
		border-top-color: red;
		border-right-color: red;
		// border-bottom-color: #76b82a;
		// border-left-color: #76b82a;
	}

	& .inner {
		width: 12.5em;
		height: 12.5em;
		margin-left: -6.75em;
		margin-top: -6.75em;
		animation: spin 1.5s linear infinite;
		// border-top-color: $color-active;
		// border-right-color: $color-active;
	}
}

@keyframes spin {
	to {
		transform: rotate(360deg);
	}
}

@media screen and (max-width: 820px) and (orientation: portrait) {
	.loader {
		& .outer {
			width: 20.5em;
			height: 20.5em;
			margin-left: -10.7em;
			margin-top: -11.7em;
		}

		& .middle {
			width: 16.5em;
			height: 16.5em;
			margin-left: -8.75em;
			margin-top: -9.75em;
		}

		& img {
			width: 45%;
		}
	}
}

@media screen and (max-width: 1200px) and (orientation: landscape) {
	.loader {
		& .outer {
			width: 20.5em;
			height: 20.5em;
			margin-left: -10.7em;
			margin-top: -11.7em;
		}

		& .middle {
			width: 16.5em;
			height: 16.5em;
			margin-left: -8.75em;
			margin-top: -9.75em;
		}

		& img {
			width: 15%;
		}
	}
}
